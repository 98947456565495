import { CreateDocumentRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';
export class Document {
    id?: number;
    lastEditDate?: Date;
    modifiedUserId?: number;
    path?: string ='/';
    isDeleted?:boolean = false;
    enterpriseId?: number;
    createdUserId?: number;
    documentSize?: number;
    filename?: string;
    documentTitle?: string;
    documentPath?: string;
    description?: string;
    url?: string;
    color?: string;
    uploadDate?: Date;
    shareAll?: boolean;
   // shareUsers?: any[];
   // shareTeams?: any[];
    sortOrder?: string;
    fileType?: string;
    file?:File;
    

    constructor(data) {
        Object.keys(data).forEach(key => {
            if(key.toLowerCase().includes('date')) 
                this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        });
        this.documentTitle = (this.documentTitle||'').replace(/%20/g,' ');        
        this.documentSize = +this.documentSize || 0;
        this.path = this.documentPath || this.path || '/';
    }
   
    static toRequest(doc): CreateDocumentRequest {
        let req: CreateDocumentRequest = {documentPath: doc.path || '/'} as CreateDocumentRequest;
        let fields = [
            'enterpriseId', 'createdUserId', 'documentSize', 'filename', 'documentTitle',
            'documentPath','description', 'url', 'color', 'uploadDate', 'shareAll', 'sortOrder', 'fileType'
        ];
        for (const key in doc) {
            if (fields.includes(key)) {
                req[key]=doc[key];                
            }
        }
        return req;
    }
}
