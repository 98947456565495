import { debounceTime, filter, map } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { GlobalService } from '@common/global/app.global.service';
import { AppDataServices } from '@store/entity/entity-services';
import { combineLatest } from 'rxjs';
import { User } from '@models/user';
import { Constants } from '@common/global/app.global.constants';



@Injectable({
    providedIn: 'root'
})
class PermissionsService {

    currentUser:any;
    roles:number[] = [];
    features:any = {};   //{goals:true, actions:true, dashboard:true, calculator:true, :true, white_label:false, documents:true, kpi:true, users:true, teams:true, suppliers:false};
    permissions:{path:string, isValid}[] = [
        { path: 'home', isValid: () => this.globals.currentUserRoleId==Constants.roles.ADMINISTRATOR && this.roles.includes(Constants.roles.ADMINISTRATOR) },
        { path: 'profile',  isValid: () => this.currentUser!=null},
        { path: 'eco-print', isValid: () => this.features.eco_print==true }, 
        { path: 'dashboard', isValid: () => this.features.dashboard==true },
        { path: 'calculator', isValid: () => this.features.calculator==true },
        { path: 'calendar',  isValid: () => !this.features.enteprise_only && !this.features.supplier_only && [Constants.roles.TEAM, Constants.roles.INDIVIDUAL].includes(this.globals.currentUserRoleId) && this.features.actions==true },
        { path: 'kpi',  isValid: () => !this.features.enteprise_only && !this.features.supplier_only && this.features.kpi==true},  
        { path: 'goals',  isValid: () => !this.features.enteprise_only && !this.features.supplier_only && this.features.goals==true},  
        { path: 'documents',  isValid: () => this.features.documents==true},
        { path: 'teams',  isValid: () => !this.features.enteprise_only && !this.features.supplier_only && [Constants.roles.TEAM, Constants.roles.INDIVIDUAL].includes(this.globals.currentUserRoleId) && this.features.teams==true},
        { path: 'individuals',  isValid: () => !this.features.enteprise_only && !this.features.supplier_only && this.features.users==true },
        { path: 'suppliers',  isValid: () =>(this.globals.currentUserRoleId==Constants.roles.ADMINISTRATOR && this.roles.includes(Constants.roles.ADMINISTRATOR)) ||
         (this.globals.isSupplyChain && this.features.suppliers==true)}, 
        { path: 'reductions',  isValid: () => !this.features.enteprise_only && !this.features.supplier_only && this.features.reductions==true},
    ];

    constructor(
        private router: Router,
        private appDataServices: AppDataServices,
        private globals: GlobalService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.appDataServices.authService.currentUser$,
            this.appDataServices.enterpriseService.currentEnterprise$,
            this.appDataServices.loaded$.pipe(filter(loaded=>!!loaded)),
        ]).pipe(
            debounceTime(200),
            map(([currentUser,currentEnterprise, loaded])=>{
                if(loaded && currentUser && currentUser.token && currentUser.user){
                    if(!this.currentUser){
                        this.currentUser = currentUser.user;
                        this.roles = (this.currentUser.roles||[]).map(item=>+item.roleNumber);
                        if(currentEnterprise){
                            for (const key in currentEnterprise.preferences.features) {
                                if((currentEnterprise.preferences.features||{})[key]!=false && (this.currentUser.preferences.features||{})[key]!=false)
                                    this.features[key]=true;
                                else
                                    this.features[key]= false;
                            } 
                        }                        
                    }
                    
                    // if Permission is found validate it otherwise go to main page                 
                    let permission = this.permissions.find(item=>state.url.includes(item.path))
                    if(permission.isValid())
                       return true;
                    else {
                        this.router.navigate(['/']);
                        return false;
                    }
                   
                }
               

                // not logged in so redirect to login
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            })
        )
    }

}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(next, state);
}