import { SaveReportRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';
export class Transport {
    quantity?:number=0;
    vehicle_unit_quantity?:number;
    vehicle_distance?:number
    type?:string;
    vehicle_size?:string;
    fuel_type?:string;
    fuel_usage?:string;
    fuel_usage_unit?:string;
    vehicle_often?:string;
    vehicle_kind?:string;
    vehicle_type?:string;
    vehicle_fuel?:string;
    vehicle_unit?:string;
    vehicle_distance_unit?:string;
    has_fuel_usage?: 'yes'|'no';
}
export class Energy {
    quantity?:number=0;
    energy_fuel?:string;
}
export class EnergySources {
    energy_sources?:Energy[];
}
export class Waste {
    quantity?:number=0;
    waste_type?:string;
    waste_unit?:string;
    waste_method?:string;
}
export class WasteSources {
    waste_sources?:Waste[];
}
export class Water {
    quantity?:number=0;
    water_unit?:string;
}
export class WaterSources {
    water_sources?:Water[];
}

export class AirCon {
    quantity?:number=0;
    equipment_type?:string;
    hfc_type?:string;
}

export class AirConUnits {
    air_con_units?:AirCon[];
}

export class Cloud {
    quantity?:number=0;
    provider?:string;
}

export class Holiday {
    quantity?:number=0;
    destination?:string;
}

export class UseTaxi {
    work?: 'yes'|'no';
    personal?: 'yes'|'no';
}

export class TaxiItem {
    taxi_distance?:number;
    taxi_unit?:string;
    taxi_type?:string;
    journeyAmount?:string;    
}

export class Taxi {
    work?: TaxiItem;
    personal?: TaxiItem;
}

export class Food {
    red_meat?: string;
    white_meat?: string;
    fish?: string;
    cheese?: string;
    milk?: number;
    eggs?: number;
    bread?: string;
    candy?: string;
    fruit?: string;
    shop_locally?: string;
}

export class Flight {
    class?:string;
    passenger_hr?:number;
    haul?:string;
}
export class FlightData {
    personal:Flight[];
    work:Flight[];
}
export class Consumer {
    cloth?:number;
    second_hand?:number;
    gadgets?:number;
    second_hand_time?:string;
    food_waste?:string;
    recycle?:string;
}
export class Social {
    bar?:number;
    restaurant?:number;
    cafe?:number;
    media?:number;
    stream?:string;
}

export class Questionnaire {
    type?:string;
    isSupplier?:boolean;

    has_meter_read?: 'yes'|'no';
    travel?: 'yes'|'no';
    own_vehicle?: 'yes'|'no';
    has_air_conditioning?: 'yes'|'no';
    has_company_vehicles?: 'yes'|'no';
    use_cloud?: 'yes'|'no';
    virtual?: 'yes'|'no';

    number_of_employees?:number;
    bedroom_in_house?: number;
    work_home?: number;
    electricity_usage?:number;
    energy_usage?:number;
    business_sector?:string;
    house_type?: string;
    location?:string;
    insulation?:string;
    energy_type?:string;
    eco_category?:string;
    bike?:string;
    bus?:string;
    train?:string;
    tube?:string;
    tram?:string;

    transport_main?: Transport[];
    transport_secondary?: Transport[];
    vehicles?: Transport[];
    transport?: Transport[];
    energy?: EnergySources;
    waste?: WasteSources;
    water?: WaterSources;
    air_conditioning?: AirConUnits;
    cloud?: Cloud[];
    use_taxi?: UseTaxi;
    taxi?: Taxi;   
    food?: Food;
    social?:Social;
    holidays_uk?:Holiday[];
    holidays_abroad?:Holiday[];
    consumer?:Consumer;
    flight?:FlightData;
    reporting_period?: {start_date: string, end_date: string};

    constructor(data) {
        for (const key in data) {
            if(data[key]!=null) {
                if(this.isNumber(data[key]))
                    this[key] = +data[key] || 0;
                else if( _.isArray(data[key])) {
                    this[key]=(data[key]||[]).map(item=>this.convertObject(item))
                }
                else if(_.isObject(data[key])) {
                    this[key]=this.convertObject(data[key])
                }
                else
                    this[key] = data[key];
            } else if(typeof(data[key])=='number')
                this[key]=0;

        }
    }

    isNumber(value) {
        return !isNaN(Number(value));
    }

    convertObject(data){
        let item = {};
        for (const key in data) {
            if(data[key]!=null) {
                if(this.isNumber(data[key]))
                    item[key] = +data[key] || 0;
                else if( _.isArray(data[key])) {
                    item[key]=(data[key]||[]).map(item=>this.convertObject(item))
                }
                else if(_.isObject(data[key])) {
                    item[key]=this.convertObject(data[key])
                }
                else
                    item[key] = data[key];
            } else if(typeof(data[key])=='number')
                item[key]=0;
        }
        return item;
    }

    static toSaveReportRequest(rep): SaveReportRequest {
        let req: SaveReportRequest = {} as SaveReportRequest;
        let fields = [  'id', 'type', 'reportData' ];
        for (const key in rep) {
            if (rep[key]!=null && fields.includes(key)) {
                req[key]=rep[key];                
            }
        }
        return req;
    }
}
