import { CreateMilestoneRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';
export class Milestone {
    completionDate: Date;
    createdDate:  Date;
    id: number;
    milestone: String;
    modifiedDate:  Date;
    targetDate:  Date;
    createdUserId?: number;    
    enterpriseId: number;
    goalId: number;
    milestoneNumber?: number;
    status: string;
    description: string;
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
    }

    static toRequest(goal, ...addFields:string[]): CreateMilestoneRequest {
        let req: CreateMilestoneRequest = {} as CreateMilestoneRequest;
        let fields = [...addFields, 'id', 'targetDate','description','enterpriseId','milestone','goalId','milestoneNumber','status'];
        for (const key in goal) {
            if (goal[key]!=null && fields.includes(key)) {
                req[key]=goal[key];                
            }
        }
        return req;
    }
}