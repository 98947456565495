import { Goal } from '@common/models/goal';
import { GlobalService } from '@common/global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import {
    DefaultDataService,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
    HttpUrlGenerator,
    MergeStrategy,
    QueryParams
} from '@ngrx/data';

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Milestone } from '@common/models/milestone';
import type { CreateGoalFromSuggestionRequest } from '@ecoshaper/lib';
import { ReductionCollectionService } from '@store/services/reduction.service';


@Injectable()
export class GoalDataService extends DefaultDataService<any> {
    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private global: GlobalService,
        private authService: AuthCollectionService,
        private reductionService: ReductionCollectionService
    ) {
        super('Goal', http, httpUrlGenerator);
    }

    getAll(): Observable<Goal[]> {
        return this.getGoals();
    }

    getById(key: string) {
        return this.http.get(`${this.global.baseURL}/gs/goals/byid/${key}`);
    }

    getWithQuery(params: string | QueryParams | any): Observable<Goal[] | any[] | any> {
        const {data} = params;
        return of(data || []);
    }

    add(goal: Goal): Observable<Goal> {
        return this.http.post<any>(this.global.createGoalUrl, Goal.toRequest(goal))
            .pipe(
                map(item => (new Goal({...goal, ...item}))),
                catchError((error) => throwError(error))
            );
    }

    addFromSuggestion(payload: CreateGoalFromSuggestionRequest): Observable<Goal> {
        return this.http
            .post<Goal>(`${this.global.baseURL}/gs/reductions/create-goal`, payload)
            .pipe(
                tap((item) => this.reductionService.updateOneInCache({
                    id: payload.reductionSuggestionId,
                    goalId: item.id
                }, {mergeStrategy: MergeStrategy.OverwriteChanges})),
                map(item => new Goal({...payload, ...item})),
            );
    }

    update(goal: Update<Goal>): Observable<any> {
        const url = this.global.updateGoalUrl.replace('{goalId}', goal.id as string);
        return this.http.put<any>(url, Goal.toRequest(goal.changes))
            .pipe(
                map(item => (new Goal({...goal.changes, ...item}))),
                catchError((error) => throwError(error))
            );
    }

    delete(key: number | string): Observable<any> {
        return this.authService.currentUser$
            .pipe(
                take(1),
                switchMap((currentUser) => {
                    const {user} = currentUser || {};
                    let url = this.global.deleteGoalUrl;
                    url = url.replace('{enterpriseId}', user.enterpriseId.toString());
                    url = url.replace('{goalId}', key as string);
                    return (!currentUser && !user) ? of(false) : this.http.delete<any>(url);
                }),
                tap(res => {
                    if (res === false) {
                        return;
                    }

                    // We do this just is case any reduction goal was deleted.
                    // Modifying a specific cached entity is too difficult so we're leaving it this way for now.
                    this.reductionService.clearCache();
                    this.reductionService.load();
                }),
                catchError((error) => throwError(error))
            );
    }

    getGoals(): Observable<Goal[]> {
        return this.authService.currentUser$.pipe(
            take(1),
            switchMap((currentUser) => {
                const {user} = currentUser || {};
                return (!currentUser && !user) ? of([] as Goal[]) :
                    this.http.get<Goal[]>(`${this.global.getFullGoals}/${user.enterpriseId}/${user.id}`);
            }),
            map(goals => {
                return goals.map(item => {
                    const goalMilestones = (item.goalMilestones || []).map(milestone => new Milestone(milestone));
                    const progress = goalMilestones.length > 0
                        ? +(((goalMilestones
                            .filter(milestone => milestone.status === 'completed')
                            .length / goalMilestones.length) * 100)
                            .toFixed(0))
                        : 0;
                    return new Goal({...item, progress, goalMilestones});
                });
            }),
            catchError(() => of([]))
        );
    }

    getKPI(): Observable<any> {
        return this.authService.currentUser$
            .pipe(
                take(1),
                switchMap((currentUser) => {
                    const {user} = currentUser || {};
                    const url = `${this.global.getKPI}/${user.enterpriseId}`;
                    return (!currentUser && !user) ? of([]) : this.http.get<any>(url);
                }),
                map(item => item && item.length > 0 ? JSON.parse(item[0].kpiData || '') || [] : item.kpiData || []),
                catchError(() => of([]))
            );
    }

    setKPI(kpi: any): Observable<any> {
        return this.authService.currentUser$
            .pipe(
                take(1),
                switchMap((currentUser) => {
                    const {user} = currentUser || {};
                    const url = `${this.global.setKPI}/${user.enterpriseId}`;
                    return (!currentUser && !user) ? of([]) : this.http.post<any>(url, kpi);
                })
            );
    }
}

@Injectable()
export class GoalCollectionService extends EntityCollectionServiceBase<any> {
    constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: GoalDataService) {
        super('Goal', elementsFactory);
    }

    load(): Observable<any> {
        return super.load()
            .pipe(
                switchMap(res => combineLatest([of(res), this.getKPI()])),
                map(([goals]) => (goals)),
                catchError(() => of({})));
    }

    get goals$(): Observable<Goal[]> {
        return this.entities$;
    }

    setData(additional: any): Observable<any> {
        const queryParams: any = {additional};
        return this.getWithQuery(queryParams);
    }

    get kpi$(): Observable<any> {
        return this.collection$.pipe(map((item: any) => item.kpi));
    }

    getKPI(): Observable<any> {
        return this.dataService.getKPI()
            .pipe(map(kpi => {
                    this.setData({kpi});
                    this.setLoaded(true);
                    return kpi;
                }),
                catchError(() => of({})));
    }

    setKPI(kpi: any): Observable<any> {
        return this.dataService
            .setKPI(kpi)
            .pipe(
                map(() => {
                    this.setData({kpi});
                    this.setLoaded(true);
                    return kpi;
                }),
                catchError(() => of({}))
            );
    }

    addFromSuggestion(payload: CreateGoalFromSuggestionRequest): Observable<Goal> {
        return this.dataService
            .addFromSuggestion(payload)
            .pipe(
                tap(goal => this.addOneToCache(goal))
            );
    }
}
