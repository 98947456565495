import { CreateSuppliersRequest } from './../../../../../lib/requests/supplier';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, take, pluck, tap } from 'rxjs/operators';
import { sortComparerName } from '../entity/entity-metadata';
import { Supplier } from '@models/supplier';
import { GlobalService } from '@common/global/app.global.service';
import { CreateSupplierRequest } from '@ecoshaper/lib';


@Injectable()
export class SupplierDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('Supplier', http, httpUrlGenerator);
  }

  getAll(): Observable<Supplier[]> {
    return this.loadSuppliers();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Supplier[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }
  add(data: Supplier): Observable<Supplier> {
    let suppliers:CreateSupplierRequest[] = [data].map(supplier=>Supplier.toRequest(supplier)) ;
    let req: CreateSuppliersRequest = {suppliers}
     return this.http.post<any>(this.global.createSupplierUrl, req)
    .pipe(
      pluck(0),
      map(item=>new Supplier(item)),
      catchError((error) => throwError(error)),
    );
  }

  import(data: Supplier[]): Observable<Supplier[]> {    
    let suppliers:CreateSupplierRequest[] = (data||[]).map(supplier=>Supplier.toRequest(supplier)) ;
    let req: CreateSuppliersRequest = {suppliers}
    return this.http.post<any>(this.global.createSupplierUrl, req)
    .pipe(
      map(res=> (res || [] ).map(item=>new Supplier(item))),
      catchError((error) => throwError(error)),
    );
  }

  update(supplier: Update<Supplier>): Observable<any> {
    let data:CreateSupplierRequest = Supplier.toRequest(supplier.changes) ;
    
    return  this.authService.currentUser$
      .pipe(
        take(1),
        switchMap( (currentUser) => {
          let {user} = currentUser || {}; 
          let url = this.global.updateSupplierUrl;
          url = url.replace('{supplierId}', <string>supplier.id);
          return (!currentUser && !user) ? of(false) : this.http.put<any>(url, data)
        }),
        map(item=> new Supplier({...supplier.changes, ...item})),
        catchError((error) => throwError(error))
      );
  }

  updateMany(items: Supplier[]): Observable<Supplier[]> {
    let suppliers:CreateSupplierRequest[] = (items||[]).map(supplier=>Supplier.toRequest(supplier)) ;
    let data:CreateSuppliersRequest = {suppliers} ;
    
    return  this.authService.currentUser$
      .pipe(
        take(1),
        switchMap( (currentUser) => {
          let {user} = currentUser || {}; 
          let url = this.global.updateSuppliersUrl;
          return (!currentUser && !user) ? of(false) : this.http.put<any>(url, data)
        }),
        map(result => {
          return (result || [] ).map(item=>new Supplier(item));
        }),
        catchError((error) => throwError(error))
      );
  }


  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
      .pipe(
        take(1),
        switchMap( (currentUser) => {
          let {user} = currentUser || {}; 
          let url = this.global.deleteSupplierUrl;
          url = url.replace('{enterpriseId}', (user.enterpriseId||0).toString());        
          url = url.replace('{supplierId}', <string>key);
          return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
        }),
        catchError((error) => throwError(error))
      );
  }

  loadSuppliers(): Observable<Supplier[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getSuppliers()
        .pipe(
          map(result => {
            return (result || [] ).map(item=>new Supplier(item));
          }))
      }),      
      catchError(() => of([]))
    );
  }

  getSuppliers(): Observable<Supplier[]> {
    let url = this.global.getSuppliersUrl;
    //url = url.replace('{enterprise_id}', enterprise_id);

    return this.http.get<Supplier[]>(url);
  }
}

@Injectable()
export class SupplierCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: SupplierDataService) {
    super('Supplier', elementsFactory);
  }

  get suppliers$(): Observable<Supplier[]> {
    return this.entities$;
  }

  import(suppliers:Supplier[]): Observable<Supplier[]> {
    return this.dataService.import(suppliers);
  }

  updateMany(suppliers:Supplier[]): Observable<Supplier[]> {
    return this.dataService.updateMany(suppliers);
  }
  

}
