import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FootprintChartComponent } from './chart/chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { SelectUsersComponent } from './select-users/select-users.component';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    FootprintChartComponent,
    SelectUsersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    NgApexchartsModule,
    NgSelectModule,
  ],
  exports:[FootprintChartComponent, SelectUsersComponent]
})
export class AppCommonModule { }
