import { UpdateActionRequest } from '@ecoshaper/lib';
import { Document } from './document';
import * as _ from 'lodash';
import { Milestone } from './milestone';
import { Goal } from './goal';
import { User } from './user';
export class Action {	
	id?: number;
    action?: string;
    createdDate?: Date | string;
    modifiedDate?: Date | string;
    createdUserId?: number;
    modifiedUserId?: number;
    scheduledDate?: Date | string;
    completionDate?: Date | string;
    targetDate?: Date | string;
    description: string = '';
    remarks?: string='';
    taskStateId?: number;
    priorityId?: number;
    enterpriseId?: number;
    goalId?: number;
    isDeleted?: boolean;
    milestoneId?: number;
    assignedById?: number;
    assignmentComments?: string;
	assignedTo?: any;
	assignedBy?: any;
	assignedActions?:Action[];
    isAssigned?: boolean
    actionCategoryId: number=0;
    sortOrder?: number;
    deletedDate?: Date | string;
    status?: string;
    parentActionId?: number;

	documents?: Document[];
	tasks?:any[];
	goal?: Goal;
    goalMilestone?: Milestone;
    constructor(data) {
		for (const key in data) {
			if(key.toLowerCase().includes('date'))
                this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else if(key=="documents") {
				if(_.isString(data[key])) {
					try {
						this[key]=JSON.parse(data[key]) || [];
						if(!Array.isArray(this[key])) {
							this[key] = [];
						}
						this[key]= (this[key]||[]).map(item=>new Document(item));
					} catch (error) {
						this[key] = [];
					}
				} else {
					this[key] = data[key] || [];
				}
				
			}
			else if(key=="tasks" || key=='assignedTo') {
				if(_.isString(data[key])) 
				{
					try {
						this[key]=JSON.parse(data[key]) || [];
						if(!Array.isArray(this[key])) {
							this[key] = [];
						}
					} catch (error) {
						this[key] = [];
					}
				} else {
					this[key] = data[key] || [];
				}
			}
			else if(key=='status')
				this[key] = data[key] !='null' ? data[key] : null;
			else if(data[key]!=null && key.toLowerCase().includes('id'))
				this[key] = +data[key];
            else
                this[key] = data[key] !='null' ? data[key] : null;
        }
		if(this.assignedTo)
			this.assignedTo = this.assignedTo.map(item=>(item||{}).id? new User(item): +item);
		if(this.assignedBy && this.assignedBy.id)
			this.assignedBy = new User(this.assignedBy);
		
		if(this.assignedActions)
			this.assignedActions = this.assignedActions
		.filter(item =>!item.isDeleted && item.createdUserId!=(this.assignedBy||{}).id)
		.map(item=>new Action({...item, assignedBy: this.assignedBy}));
		if(this.goal)
			this.goalId = (this.goal||{}).id || this.goalId || null;
		if(this.goalMilestone)
			this.milestoneId = (this.goalMilestone||{}).id || this.milestoneId || null;
		if(this.goalId==0)
			this.goalId = null;
		if(this.milestoneId==0)
			this.milestoneId = null;
    }

	static toRequest<T>(action, ...addFields:string[]):T {
		let data = new Action(action);
		let req:T = {} as T;
		let fields = [...addFields,'action','description','sortOrder','documents','enterpriseId',
		,'milestoneId','scheduledDate','completionDate','targetDate','goalId', 'actionCategoryId','assignedTo','tasks','status'];
        for (const key in data) {
			if(fields.includes(key))
				req[key] = data[key]!=null && key.toLowerCase().includes('id') ? +data[key] : data[key];
			
			if(key=='assignedTo' || key=='documents'){
				req[key] = (data[key]||[]).map(item=>+item.id || item);
			}
			if(key=='tasks') {
				req[key] = (data[key]||[]).map(({name, status})=>({name, status}));
			}
        }
        return req;
    }


	
}
