import { TeamCollectionService } from './team.service';
import { UserCollectionService } from './user.service';
import { GlobalService } from '@common/global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { map, catchError, tap, switchMap, take, mergeMap, filter } from 'rxjs/operators';
import { Document } from '@common/models/document';
import * as _ from 'lodash';
import { once } from 'events';


@Injectable()
export class DocumentDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService , private userService: UserCollectionService, private teamService: TeamCollectionService) {
    super('Document', http, httpUrlGenerator);
  }

  getAll(): Observable<Document[]> {
    return this.loadDocuments();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Document[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(document: Document): Observable<Document> {    
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.createDocumentUrl;
        url = url.replace('{userId}', user.id.toString());
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        let item = Document.toRequest({...document,
          createdUserId : user.id,
          modifiedUserId : user.id,
          enterpriseId : user.enterpriseId
        });
        return (!currentUser && !user) ? of(null) : 
        this.http.post(url, item)
        .pipe(map((res:any)=>(new Document({...item, ...res }))
        ))
      }),
      tap(result=>{
        if (result && result.tempUrl && document.file)
            this.uploadFile(result.tempUrl, document.file).subscribe();
      }),
      catchError((error) => throwError(error))
    );
  }

  update(document: Update<Document>): Observable<any> {
    let {changes} = document || {};
    let restore = (changes.isDeleted);
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.updateDocumentUrl;
        url = url.replace('{userId}', user.id.toString());
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        url = url.replace('{document_id}', <string>document.id);
        let item = Document.toRequest({...changes, modifiedUserId : user.id});
        return (!currentUser && !user) ? of(null) : 
        this.http.put<any>(url, item, { params: restore ? {restore}:undefined})
        .pipe(map((res:any)=>(new Document({...item, ...res }))))
     }),
      tap(result=>{
        if (result && result.tempUrl && changes.file)
          this.uploadFile(result.tempUrl, changes.file).subscribe();
      }),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.deleteDocumentUrl;
        url = url.replace('{userId}', user.id.toString());
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        url = url.replace('{document_id}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  loadDocuments(params?): Observable<Document[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getAllDocuments(user.enterpriseId, user.id, params)
        .pipe(
          map(result => {
            return result.map(item =>  new Document(item))
          })
        )
      }),      
      catchError(() => of([]))
    );
  }

  getAllDocuments(enterpriseId:any, userId:any, params:any): Observable<any> {
    let url = this.global.getAllDocumentsUrl;
    url = url.replace('{enterpriseId}', enterpriseId);
    url = url.replace('{userId}', userId);
    return this.http.get<any>(url, { params });
  }


  view(document: Document): Observable<any> {
    let url = this.global.viewDocumentUrl;
    url = url.replace('{userId}', document.createdUserId.toString());
    url = url.replace('{enterpriseId}', document.enterpriseId.toString());
    url = url.replace('{document_id}', document.id.toString());
    return this.http.get<any>(url)
    .pipe(
      take(1),
      catchError((error) => throwError(error))
    );
  }

  empty(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.emptyTrashUrl;
        url = url.replace('{userId}', user.id.toString());
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  uploadFile(url:any, file:any):Observable<any>{
    return this.http.put<any>(url, file);
  }

}

@Injectable()
export class DocumentCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: DocumentDataService) {
    super('Document', elementsFactory);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  get documents$(): Observable<Document[]> {
    return this.entities$;
  }

  get trash$(): Observable<Document[]> {
    return this.collection$.pipe(map((item: any) => item.trash));
  }

  empty(): Observable<any> {
    return this.dataService.empty().pipe(tap(() => super.load()));
  }

  view(document: Document): Observable<any> {
    return this.dataService.view(document);
  }
  uploadFile(url:any, file:any):Observable<any>{
    return  this.dataService.uploadFile(url, file);
  }

  addFile(entity, options?, folderName='Emission reports'){
    return combineLatest([
      this.loaded$.pipe(filter(loaded => !!loaded)), // filter when loaded only
      this.entities$
    ]).pipe(
      take(1),
      mergeMap(([loaded, docs])=>{
        let folder = docs.find(item=>item.documentTitle === folderName && !item.isDeleted);
        if(!folder){
          folder = {
            color: "#759AFF",
            path: "/",
            uploadDate: new Date(),
            fileType: 'folder',
            documentTitle : folderName,
            filename : folderName,
            description: '',
            shareAll: false,
            documentSize: 0,
            url:'',
            sortOrder:''
          };
          return this.add(folder).
          pipe(mergeMap(res=>{
            let path =(res||{}).id?  `/${res.id}/` :'/';
            return this.add({...<any>entity,path }, options);        
          }));
        } else if(folder.id){
          let path = `/${folder.id}/`
         return this.add({...<any>entity,path }, options);
        } else
        return this.add(entity, options);
      })
    )    
  }

}
