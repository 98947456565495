import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';
import { Observable, of, combineLatest } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import * as calculator_data from '@assets/data/carbon_calculator.json';
import * as questions_data from '@assets/data/questions.json';
import { Questionnaire } from '@common/models/questionnaire';
import { GlobalService } from '@common/global/app.global.service';



@Injectable()
export class CalculatorDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('Calculator', http, httpUrlGenerator);
  }

  getAll(): Observable<any[]> { // load for now the current stored report in localStorage
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.loadReports(user.enterpriseId, user.id)
      }),
      catchError(() => of([]))
    );
  }

  getWithQuery(params: string | QueryParams | any): Observable<any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(report: any): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of({}) : 
        this.saveReport(user.enterpriseId, user.id, report)
      }),      
      catchError(() => (of({})))
    );
  }

  update(report: Update<any>): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.saveReport(user.enterpriseId, user.id, {...report.changes})
      }),      
      catchError(() => (of({})))
    );
  }

  delete(key: number | string): Observable<any> {   
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.deleteReport;
        url = url.replace('{userId}', user.id.toString());
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        url = url.replace('{report_id}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      })
    );
  }

  loadCalculator(): Observable<any> {
    let calculator = calculator_data;
    return this.http.get(this.global.getQuestions)
    .pipe(
     map((item: any)=> {
      let {dictionary}= item || {};
      return {...calculator, dictionary};
    }),
    catchError(()=>{
      return of({...calculator, dictionary:{}})
    }))
  }
  
  loadReports(enterpriseId, user_id?): Observable<any[]> {
    return this.http.get(`${this.global.getReports}/${enterpriseId}/${user_id||0}`)
    .pipe(
      map((array:any) => {
       // console.log(array);
        return (array ||[]).map(item=> {
          let {reportData, ...rest} = item;
          if(reportData) {
            try {
              let data = JSON.parse(reportData);
              let createdDate = new Date(item.createdDate || data.createdDate);
              return {...data, ...rest, createdDate};
            } catch (error) {
              return null;
            }
          }            
          return item;
        }).filter(item=>item!=null);
      }));
  }

  saveReport(enterpriseId, user_id, report):Observable<any> {
    let saveReq = Questionnaire.toSaveReportRequest({
      id:report.report_id.toString(), 
      reportData: JSON.stringify(report), 
      type: report.type
    });
    return this.http.post(`${this.global.saveReport}/${enterpriseId}/${user_id}/${(report.report_id || report.reportID)}`, JSON.stringify(saveReq))
    .pipe(
      map((res:any) => {
        if(!res.message) {
          let rep = Array.isArray(res) ? res[0] : res;
          try {
            rep = JSON.parse(rep.reportData);
            rep.createdDate = new Date(rep.createdDate);
          } catch (error) {
            rep = Array.isArray(res) ? res[0] : res;
          }  
          return ({ ...report, ...rep})
        } else {
          return {...report, id:report.report_id.toString()};
        }
        
      })
    );
  }

  getEnterpriseClimateTotals(dates):Observable<any> {    
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.getEnterpriseClimateTotals;
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        return (!currentUser && !user) ? of(null) : this.http.post<any>(url, JSON.stringify(dates));
      }),
      catchError(() => of(null))
    );
  }

  getEnterpriseEmployeeScope3():Observable<any> {    
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.getEnterpriseEmployeeScope3;
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        return (!currentUser && !user) ? of(null) : this.http.get<any>(url);
      }),
      catchError(() => of([]))
    );
  }

  calculate(data, is_enterprise = false):Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.calculate;
        url = url.replace('{userId}', user.id.toString());
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        url = url.replace('{type}', is_enterprise ? 'enterprise':'individual');
        return (!currentUser && !user) ? of(null) : this.http.post<any>(url, JSON.stringify(data));
      })
    );
  }

  loadAll(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        combineLatest([this.loadCalculator(), this.loadReports(user.enterpriseId)])        
        .pipe(map(([calculator, allReports])=>({calculator, allReports})))
      }),      
      catchError(() => of([]))
    );
  }

  
}

@Injectable()
export class CalculatorCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private service: CalculatorDataService) {
    super('Calculator', elementsFactory);
  }

  load(): Observable<any[]|any> {
    return combineLatest([
      this.service.loadAll(),
      super.load()
    ]).pipe(map(([{calculator, allReports}]) => {
      this.setData({...calculator, allReports});
      this.setLoaded(true);
      return calculator;
    }));
  }

  calculate(data, is_enterprise = false):Observable<any> {
    return this.service.calculate(data, is_enterprise)
    .pipe(
      take(1),
      catchError(() => of(is_enterprise? [] : {}))
    );
  }

  getEnterpriseClimateTotals(dates?):Observable<any> {
    return this.service.getEnterpriseClimateTotals(dates);
  }

  getEnterpriseEmployeeScope3():Observable<any> {
    return this.service.getEnterpriseEmployeeScope3();
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  loadReports(enterpriseId, user_id?): Observable<any[]> {
    return this.service.loadReports(enterpriseId, user_id);
  }
  
  get allReports$(): Observable<any[]> {
    return this.collection$.pipe(map((item: any) => item.allReports));
  }

}
