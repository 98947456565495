
import { CreateGoalRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';
import { Milestone } from './milestone';
export class Goal {
    completionDate: Date;
    createdDate:  Date;
    createdUserId: number;
    description: String;
    enterpriseId: String;
    firstname: String;
    goalCategoryId: number;
    id: number;
    goalId: number;
    goalLevelId: number;
    goalMembers: number[] | any[];
    goalMilestones: Milestone[] = [];
    goalTeams: String;
    goalTitle: String;
    goalTypeId: number;
    lastname: String;
    milestoneCount: number;
    modifiedDate:  Date;
    modifiedUserId: String;
    remarks: String;
    rowNumber: number;
    scheduledDate:  Date;
    targetDate:  Date;
    totalCount: number;
    progress?:number = 0;
    milestones?:any = [];
    taskStateId:number;
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        this.goalMilestones = (this.goalMilestones||[]).map(item=>new Milestone(item));
        this.progress =  Math.round((this.goalMilestones.filter(item=>item.status=='completed').length / this.goalMilestones.length)*100);
    }
    static toRequest(goal:Partial<Goal>): CreateGoalRequest {
        let req: CreateGoalRequest = {} as CreateGoalRequest;
        let fields = ['targetDate','description','enterpriseId','createdUserId','modifiedUserId','goalMembers','goalTeams','goalTitle',
        'completionDate','remarks','goalLevelId','scheduledDate','goalCategoryId','status','taskStateId', 'goalTypeId', 'goalMilestones'];
        for (const key in goal) {
            if (goal[key]!=null && fields.includes(key)) {
                req[key]=goal[key];                
            }
            if(key=='goalMilestones')
                req[key] = (goal[key]||[]).map(item=>Milestone.toRequest(item));
            if(key=='goalMembers')
                req[key] = (goal[key]||[]).map(item=>item.id || item.userId || item);
        }
        return req;
    }
}