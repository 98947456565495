import { UpdateUserRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';

export class AuthUser {
    token: string;
    user: User;
    expiry: Expiry;
    constructor(data) {
        let {expiry, user, token} = data || {};
        if(user)
            this.user = new User({...user});
        
        if(expiry)
            this.expiry = new Expiry({...expiry});
        this.token = token || '';
    }
}

export class Expiry {
    userId?:number;
    username?: string;
    enterpriseId?:number;
    iat?:number;
    constructor(data) {
        for (const key in data) {
            if(data[key])
                this[key] = data[key];
        }
    }
  }

export class User {
    id:number;
    username: string;
    name: string;
    firstName: string = '';
    lastName: string = '';
    email: string;
    profilePhoto: string;
    createdDate: Date;
    modifiedDate:Date;
    isFirstTime: boolean;
    designation: string = '';
    phoneNumber: string = '';
    employeeId: number;
    masterId: string;
    lastLogin: Date;
    preferences: any;
    enterpriseId: number;
    maxSize: number = 100 * 1024 * 1024;
    roles?:Role[];
    

    constructor(data) {
        if(!data) return;
        for (const key in data) {
            if(key.toLowerCase().includes('date') || key.toLowerCase()==='lastLogin')
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]);
            else if(key=='preferences'){
                if(_.isString(data[key]) && data[key]=='[object Object]')
                    data[key]=null;
                this[key] = ((data[key] && _.isString(data[key])) ? JSON.parse(data[key]) : data[key]) || data[key] || {};
            } 
            else if(key=='roles'){
                this[key]= data[key] as Role[];
            }
            else if(data[key])
                this[key] = data[key];
        }
        this.name = [this.firstName||'', this.lastName||''].join(' ').trim();
        if(!this.username)
            this.username = this.email;
    }
    getRole(roleNumber) {
        return (this.roles||[]).find(item=>item.roleNumber==roleNumber) || null;
    }

    static toRequest(user): UpdateUserRequest {
        user = new User(user);
        let data = {...user,
            roles: user.roles.map(({name,roleNumber})=>({name,roleNumber})),
            preferences: JSON.stringify(user.preferences || {}) || null
        };
        let req:UpdateUserRequest = {} as UpdateUserRequest;
        let fields = ['username','firstName','lastName','email','enterpriseId',
        'designation','phoneNumber','profilePhoto','employeeId','preferences','roles'];
        for (const key in data) {
            if (fields.includes(key)) {                
                req[key]=data[key];                
            }            
        }
        return req;
    }
}

export class Role {
    roleNumber: number;
    name: string;
    description: string;
    constructor(data) {
        if(!data) return;
        for (const key in data) {
            if(data[key])
                this[key] = data[key];
        }
    }
}