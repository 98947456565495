import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class SiteDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Site', http, httpUrlGenerator);
  }

  loadSite(): Observable<any> {
    return this.http.get(environment.siteInfo);
  }

  getWithQuery(params: string | QueryParams | any): Observable<any[] | any> {
    const {data} = params;
    return of(data || []);
  }
  
}

@Injectable()
export class SiteCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private siteService: SiteDataService) {
    super('Site', elementsFactory);
  }

  load(): Observable<any[]|any> {    
    return this.siteService.loadSite().pipe(map(siteInfo => {
      this.setData({siteInfo});
      this.setLoaded(true);
      return siteInfo;
    }));
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  get siteInfo$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.siteInfo));
  }

}
