import { Document } from '@common/models/document';
import { DocumentCollectionService } from './document.service';
import { Action } from '@common/models/action';
import { GlobalService } from '@common/global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher, EntityActionOptions } from '@ngrx/data';

import { Observable, of, throwError, combineLatest } from 'rxjs';
import { map, catchError, switchMap, filter, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { CreateActionRequest, UpdateActionRequest } from '@ecoshaper/lib';

@Injectable()
export class ActionDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private documentService: DocumentCollectionService, private authService: AuthCollectionService ) {
    super('Action', http, httpUrlGenerator);
  }

  getAll(): Observable<Action[]> {
    return this.getAllActions();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Action[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(action: Action): Observable<Action> {
    let documents: any = ((action||{}).documents || []).map(item=>item.id);
    let req = Action.toRequest<CreateActionRequest>({...action, documents}, 'createdUserId');
    return this.http.post<any>(this.global.createActionsUrl, req)
    .pipe(
      map(item=>{
        let documents= ((item||{}).documents || []).map(doc=>new Document(doc));
        return new Action({...action, ...item, documents});
      }),
      catchError((error) => throwError(error))
    );
  }

  update(action: Update<Action>): Observable<any> {
    let {changes} = action;
    let documents: any = ((changes||{}).documents || []).map(item=>item.id);
    let url = this.global.updateActionsUrl.replace('{actionId}', <string> action.id);
    let req = Action.toRequest<UpdateActionRequest>({...changes, documents});
    
    return this.http.put<any>(url, req)
    .pipe(
      map(item=>{
        let documents= ((item||{}).documents || []).map(doc=>new Document(doc));
        return new Action({...changes, ...item, documents});
      }),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.deleteACtionsUrl;
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        url = url.replace('{actionId}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getAllActions(): Observable<Action[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return ((!currentUser && !user) ? of([]) :
        this.getActions(user.enterpriseId, user.id, ''))
        .pipe(
          map(result => {
            return (result || [] ).map(item=> {
              let documents = (item.documents || []).map(doc=> new Document(doc));
              return new Action({...item, documents});
            })
          }))
      }),      
      catchError(err => of([]))
    );
  }

  getActions(enterpriseId:any, userId:any, categoryId:any): Observable<any> {
    let url = this.global.getAllActionsUrl;
    url = url.replace('{enterpriseId}', enterpriseId);
    url = url.replace('{userId}', userId);
    return this.http.get<any>(url);
  }

}

@Injectable()
export class ActionCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: ActionDataService) {
    super('Action', elementsFactory);
  }

  get actions$(): Observable<Action[]> {
    return this.entities$;
  }

}
