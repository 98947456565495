import { UserLog } from '@common/models/userLog';
import { GlobalService } from '@common/global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, take, pluck } from 'rxjs/operators';
import { sortComparerName } from '../entity/entity-metadata';
import { User } from '@common/models/user';
import { UpdateUserRequest } from '@ecoshaper/lib';


@Injectable()
export class UserDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('User', http, httpUrlGenerator);
  }

  getAll(): Observable<User[]> {
    return this.loadUsers();
  }

  getWithQuery(params: string | QueryParams | any): Observable<User[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(users: User[]): Observable<User> {    
    let data:UpdateUserRequest[] = (users||[]).map(user=>User.toRequest(user)) ;
    
     return this.http.post<any>(this.global.createUserUrl, {users:data})
    .pipe(
      pluck(0),
      map(res=> (new User({...users[0], ...(res||{})}))),
      catchError((error) => throwError(error)),
    );
  }

  update(user: Update<User>): Observable<any> {
    let data:UpdateUserRequest = User.toRequest(user.changes) ;
    let url = this.global.updateUserUrl.replace('{userId}', <string> user.id);
    return this.http.post<any>(url, data)
    .pipe(
      map(item=> new User({...user.changes, ...item})),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    let url = this.global.deleteUserUrl.replace('{userId}', <string>key);
    return this.http.delete<any>(url)
    .pipe(
      catchError((error) => throwError(error))
    );
  }

  loadUsers(): Observable<User[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getUsers(user.enterpriseId)
      }),
      map(result => (result||[]).map(item=>new User(item))),
      catchError(() => of([]))
    );
  }

  getUsers(enterpriseId:any): Observable<User[]> {
    let url = this.global.getUsersUrl;
    url = url.replace('{enterpriseId}', enterpriseId);
    return this.http.get<User[]>(url);
  }

  getTeamManagers(): Observable<User[]> {
    let url = this.global.getTeamManagers;
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        if(user) url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        return (!currentUser && !user) ? of([]) : this.http.get<any>(url)
        .pipe(
          map(result => {
            return (result || [] )
            .map(item =>  new User(item))
            .sort(sortComparerName);
          }))
      }),      
      catchError(() => of([]))
    );
    
  }
  

  createUserLog(activity, description): Observable<any>{   
    // not implemented in the new refactored api
    return of({});
     return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let userLog:UserLog = {activity, description,
          enterpriseId: user.enterpriseId.toString(),
          userId: user.id.toString()
        }
        return this.http.post<any>(this.global.userLogsUrl, userLog);
      }),      
      catchError((error) => throwError(error))
    );
  }
}

@Injectable()
export class UserCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: UserDataService) {
    super('User', elementsFactory);
  }

  get users$(): Observable<User[]> {
    return this.entities$;
  }

  createUserLog(activity, description): Observable<any>{
    return this.dataService.createUserLog(activity, description);
  }

  getTeamManagers(): Observable<any>{
    return this.dataService.getTeamManagers();
  }

  getUsers(enterpriseId): Observable<User[]>{
    return this.dataService.getUsers(enterpriseId)
    .pipe(
      map(result => (result||[]).map(item=>new User(item))),
      catchError(() => of([]))
    );

  }

}
