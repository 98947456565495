
import { CreateActionCategoryRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';
export class Category {
    categoryType: string;
    color: string;
    createdDate: Date;
    description: string;
    enterpriseId: string;
    modifiedDate: Date;
    name: string;
    userId: string;
    
    id?: number;
    label?: string = '';
    actions?:any[] = [];
    quickAction?: '';
					
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
                this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
       this.label = this.name || this.label || '';
    }

    static toRequest(category): CreateActionCategoryRequest {
        let req: CreateActionCategoryRequest = {} as CreateActionCategoryRequest;
        let fields = ['name','description','enterpriseId','color','userId','roleId','categoryType'];
        for (const key in category) {
            if (category[key]!=null && fields.includes(key)) {
                req[key]=category[key];                
            }
        }
        return req;
    }
}