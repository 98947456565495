import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpHeaders, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import sha1 from 'sha1';
import moment from 'moment';
import serverConfiguration from '@assets/config/server-config.json';
import { GlobalService } from '@common/global/app.global.service';

@Injectable()
export class HttpInterceptors implements HttpInterceptor{
    public regularSecretKey: string = 'goalShaper_secretKey';
    constructor(private global: GlobalService){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        var UTCDate = moment(new Date()).utc().format(); //'YYYY-MM-DD HH:mm:ss.SSS'
        var finalEpocTime = moment(UTCDate).unix();
        var keyCombination = this.regularSecretKey + finalEpocTime;
        var accessToken = sha1(keyCombination);
        let serverConfig = serverConfiguration;

        let hideError = request.headers.get('X-hideError');            
        if(request.url.includes(serverConfig.host)) {
            let headers:HttpHeaders;
            if(this.global.currentUserToken){
                let auth = this.global.currentUserToken.includes('@@') ? `Bearer ${this.global.currentUserToken}` : `Bearer ${this.global.currentUserToken}@@${accessToken}`;
                headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                    'timestamp': finalEpocTime.toString()
                }) 
               
            }else{
                headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`,
                        'timestamp': finalEpocTime.toString()
                })
            }
            if(hideError)
                headers = headers.append('X-hideError', hideError);
            request = request.clone({headers});
        }
        return next.handle(request);
    }
}