import { Injectable } from '@angular/core';
import * as json2csv from 'json2csv'; // convert json file to csv
import { saveAs } from 'file-saver';  // save the file


@Injectable({ providedIn: 'root' })
export class ShareService {
    Json2csvParser = json2csv.Parser;

    constructor() {

    }
    public downloadFile(data: any, fields: string[], filename?: string) {
        let csvData = this.convertToCSV(data, fields);
        let file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(file, filename + ".csv");
    }



    public convertToCSV(objArray: any, fields?: any) {
        let csv: string = '';
        try {
            const opts = { fields };
            let json2csvParser = new this.Json2csvParser(opts);
            csv = json2csvParser.parse(objArray);
        } catch (err) {
            console.error(err);
        } finally {
            return csv;
        }
    }



}