
import { CreateSupplierRequest } from '@ecoshaper/lib';
import * as _ from 'lodash';
export class Supplier {
    id?: number;
    supplierId?: number;
    enterpriseId?: number;
    parentEnterpriseId?: number;  
    supplierName?: string;
    supplierAddress?: string;
    supplierContactName?: string;
    supplierContactEmail?: string;
    supplierContactPhone?: string;
    supplierRegistrationNumber?: string;
    supplierWebsite?: string;
    createdDate?: Date | string;
    updatedDate?: Date | string;
    supplierRevenue?:  number;
    apportionPercent?:  number; 
    supplierSpend?:  number;
    supplierScope1?:  number;
    supplierScope2?:  number;
    supplierTotalScope?:  number;
    supplierScope3?:  number;
    surveyProgress?:boolean;
    surveyUrl?:string;
    active?:boolean;
    SupplierOnEnterprise?:any[];
    surveyResponse?:any;
    report?:any; // supplier's calculator report
    supplierEmmissions?:number = 0;

    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        this.supplierTotalScope = (this.supplierScope1||0)+(this.supplierScope2||0)+(this.supplierScope3||0);
        if(this.id && !this.supplierId)
            this.supplierId = this.id;
        if(this.surveyResponse && _.isString(this.surveyResponse)){
            this.surveyResponse = JSON.parse(this.surveyResponse);
            let formResult = (this.surveyResponse?.questions||[]).reduce((acc,curr)=>{
                let item = {
                    label: curr.msfp_questiontext||'',
                    value: (this.surveyResponse?.responseDetails||{})[curr.msfp_sourcequestionidentifier] || '- no answer -'
                }
                return item.label ? [...acc,item] : acc;
            },
            []);
            this.surveyResponse = {...this.surveyResponse, formResult};            
        }
            
    }

    static toRequest(data): CreateSupplierRequest {
        let req:CreateSupplierRequest = {} as CreateSupplierRequest;
        let num = ['supplierRevenue', 'apportionPercent', 'supplierSpend',
            'supplierScope1', 'supplierScope2', 'supplierScope3'];
        let fields = ['supplierId','enterpriseId','parentEnterpriseId',
        'supplierName','supplierAddress','supplierContactName', 'apportionPercent', 'supplierContactEmail','supplierContactPhone',
        'supplierRegistrationNumber','supplierWebsite',
        'createdDate','updatedDate','supplierRevenue','supplierSpend','supplierScope1','supplierScope2','supplierScope3',
        'surveyProgress','surveyUrl','active' ];
        for (const key in data) {
            if (num.includes(key) || (data[key] && fields.includes(key))) {                
                req[key]= num.includes(key)? (+data[key]||0) : data[key];                
            }
        }        
        return req;
    }
}