import { User } from '@common/models/user';
import { UserCollectionService } from './user.service';
import { GlobalService } from '@common/global/app.global.service';

import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of, throwError, combineLatest } from 'rxjs';
import { map, catchError, mergeMap, switchMap, filter, take } from 'rxjs/operators';
import { sortComparerName } from '../entity/entity-metadata';
import { Team } from '@common/models/team';
import { CreateTeamRequest } from '@ecoshaper/lib';


@Injectable()
export class TeamDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private userService: UserCollectionService, private authService: AuthCollectionService ) {
    super('Team', http, httpUrlGenerator);
  }

  getAll(): Observable<Team[]> {
    return this.getAllTeams();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Team[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(team: Team): Observable<Team> {
    let data:CreateTeamRequest = Team.toRequest(team) ;    
    return this.http.post<any>(this.global.createTeamUrl, data)
    .pipe(
      mergeMap(teams=> combineLatest([
        Array.isArray(teams) && teams.length>0 ? of(teams[0]): of(teams),
        this.userService.collection$.pipe(filter(item=>!!item.loaded))
        ])),         
      map(([item, usersCollection]) => {
        let {teamManager, teamMembers, teamManagerId} = item;
        teamManager = usersCollection.entities[teamManagerId || teamManager.id] || new User({...teamManager});
        teamMembers = (teamMembers || teamMembers || []).map(member=> usersCollection.entities[member] || new User({id:member})).sort(sortComparerName); 
        return new Team({...team, ...item, teamManager, teamMembers});
      }),
      catchError((error) => throwError(error))
    );
  }

  update(team: Update<Team>): Observable<any> {
    let data:CreateTeamRequest = Team.toRequest(team.changes) ;    
    let updateTeamUrl = this.global.updateTeamUrl.replace('{team_id}', <string>team.id);
    return this.http.put<any>(updateTeamUrl, data)
    .pipe(
      mergeMap(team=> combineLatest([
        of(team),
        this.userService.collection$.pipe(filter(item=>!!item.loaded))
        ])),         
      map(([item, usersCollection]) => {
        let {teamManager, teamMembers, teamManagerId} = item;
        teamManager = usersCollection.entities[teamManagerId || teamManager.id] || new User({...teamManager});
        teamMembers = (teamMembers || []).map(member=> usersCollection.entities[member] || new User({id:member})).sort(sortComparerName); 
        return new Team({...team.changes ,...item, teamManager, teamMembers});
      }),
      catchError((error) => throwError(error))
    );
  }

  delete(key: string | number): Observable<any> {
    let deleteTeamUrl = this.global.deleteTeamUrl.replace('{team_id}', <string>key);
    return this.http.delete(deleteTeamUrl)
    .pipe(
      catchError((error) => throwError(error))
    );
  }

  getAllTeams(): Observable<Team[]> {    
    return this.authService.currentUser$.pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};        
        return (!currentUser && !user) ? of([]) :
        this.http.get<any>(this.global.getTeamUrl.replace('{enterpriseId}', user.enterpriseId.toString()))
      }),
      mergeMap(teams=> combineLatest([
        of(teams),
        this.userService.collection$.pipe(filter(item=>!!item.loaded))
      ])),         
      map(([teams, usersCollection]) => {
        return teams.map(item=>{
          let {teamManager, teamMembers, teamManagerId} = item;
          teamManager = usersCollection.entities[teamManagerId || teamManager.id] || new User({...teamManager});
          teamMembers = (teamMembers || []).map(member=> usersCollection.entities[member] || new User({id:member})).sort(sortComparerName); 
          return new Team({...item, teamManager, teamMembers});
        })          
      }),
      catchError(() => of([]))
    );
  }
}

@Injectable()
export class TeamCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory,private userService: UserCollectionService) {
    super('Team', elementsFactory);
  }

  get teams$(): Observable<Team[]> {
    return this.entities$;
  }

}
