import { Injectable } from '@angular/core';
import { EntityServicesBase, EntityServicesElements } from '@ngrx/data';
import { combineLatest, of, BehaviorSubject } from 'rxjs';
import { catchError, tap, debounceTime, concatMap } from 'rxjs/operators';
import { TeamCollectionService } from '../services/team.service';
import { UserCollectionService } from '../services/user.service';
import { AuthCollectionService } from '../services/auth.service';
import { SiteCollectionService } from '../services/site.service';
import { CalculatorCollectionService } from '../services/calculator.service';
import { GoalCollectionService } from '../services/goal.service';
import { DocumentCollectionService } from './../services/document.service';
import { CommonCollectionService } from './../services/common.service';
import { ActionCollectionService } from './../services/action.service';
import { CategoryCollectionService } from './../services/category.service';
import { MilestoneCollectionService } from './../services/milestone.service';
import { EnterpriseCollectionService } from '../services/enterprise.service';
import { Constants } from '@common/global/app.global.constants';
import { GlobalService } from '@common/global/app.global.service';
import { SupplierCollectionService } from '@store/services/supplier.service';
import { ReductionCollectionService } from '@store/services/reduction.service';


@Injectable({ providedIn: 'root' })
export class AppDataServices extends EntityServicesBase {
    loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        entityServicesElements: EntityServicesElements,
        public userService: UserCollectionService,
        public teamService: TeamCollectionService,
        public goalService: GoalCollectionService,
        public milestoneService: MilestoneCollectionService,
        public categoryService: CategoryCollectionService,
        public actionService: ActionCollectionService,
        public documentService: DocumentCollectionService,
        public commonService: CommonCollectionService,
        public enterpriseService: EnterpriseCollectionService,
        public siteService: SiteCollectionService,
        public calculatorService: CalculatorCollectionService,
        public authService: AuthCollectionService,
        public supplierService: SupplierCollectionService,
        public reductionService: ReductionCollectionService,
        private global:GlobalService,
    )
    {
        super(entityServicesElements);
        this.registerEntityCollectionServices([
            enterpriseService, userService, authService, teamService, siteService, calculatorService, commonService,
            goalService, milestoneService, categoryService, actionService, supplierService, reductionService]);
    }


    loadApp() {
       if(location.href.indexOf('login')==-1 && location.hash.indexOf('/')>0) // show if it's not on the login where there is already spinner on button
        this.global.showLoading();
       return this.loadPublic()
       .pipe(
            concatMap(([site, currentUser]) => {
                let admin = currentUser ? !!((currentUser||{}).user||{}).getRole(Constants.roles.ADMINISTRATOR) : null;
                return combineLatest([of(site), of(currentUser), currentUser ? this.loadSecure(admin) : of([])]);
            }),            
            debounceTime(200),
            tap(()=>{
                this.global.hideLoading();
                this.loaded$.next(true);                
            }),
            catchError((err)=> {
                this.global.hideLoading();
                console.error(err);
                this.loaded$.next(true);                
                return of([]);
            }),
        );
    }

    loadPublic() {
       return combineLatest([           
           this.siteService.load(),
           this.authService.load()
        ]);
    }

    loadSecureApp(admin) {
        return this.loadSecure(admin)
        .pipe(
            tap(()=>{
                this.loaded$.next(true);
                this.global.hideLoading();
            }),
            catchError((err)=> {
                console.error(err);
                this.loaded$.next(true);
                this.global.hideLoading();
                return of([]);
            })
        );
    }

    loadSecure(admin:boolean) {
         return this.enterpriseService.load()         
        .pipe(            
            concatMap((res)=>combineLatest([of(res), admin? of([]): this.userService.load()])),
            concatMap((res)=>combineLatest([of(res),
                admin? this.commonService.loadAdmin(): this.commonService.load(),
                admin? of([]): this.teamService.load(),
                admin? of([]): this.milestoneService.load(),
            ])),
            concatMap((res)=>combineLatest([of(res),
                admin? of([]): this.goalService.load(),
                admin? of([]): this.categoryService.load(),
                admin? of([]): this.calculatorService.load(),
                admin ? of([]) : this.reductionService.load()
            ])),
            concatMap((res)=>combineLatest([of(res), admin? of([]): this.documentService.load()])), // dependent on users & teams
            concatMap((res)=>combineLatest([of(res), admin? of([]): this.actionService.load(), this.supplierService.load()])), // dependent on documents
          );
    }
}