import { DocumentDataService, DocumentCollectionService } from './../services/document.service';
import { CommonDataService, CommonCollectionService } from './../services/common.service';
import { ActionCollectionService, ActionDataService } from './../services/action.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityDataService, EntityDataModule, DefaultDataServiceConfig, EntityCollectionReducerMethodsFactory, PersistenceResultHandler, EntityServices } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { AppDataServices } from './entity-services';
import { AdditionalEntityCollectionReducerMethodsFactory, AdditionalPropertyPersistenceResultHandler } from './entity-collection';
import { UserDataService, UserCollectionService } from '../services/user.service';
import { AuthDataService, AuthCollectionService } from './../services/auth.service';
import { TeamDataService,TeamCollectionService } from './../services/team.service';
import { SiteDataService,SiteCollectionService } from './../services/site.service';
import { CalculatorDataService,CalculatorCollectionService } from './../services/calculator.service';
import { GoalCollectionService, GoalDataService } from '../services/goal.service';
import { MilestoneCollectionService, MilestoneDataService } from '../services/milestone.service';
import { CategoryCollectionService, CategoryDataService } from '../services/category.service';
import { EnterpriseDataService, EnterpriseCollectionService } from './../services/enterprise.service';
import { SupplierCollectionService, SupplierDataService } from '@store/services/supplier.service';
import { ReductionCollectionService, ReductionDataService } from '@store/services/reduction.service';


const defaultDataServiceConfig: DefaultDataServiceConfig = {
    //root: CONSTANTS.HOST + '/api',
    timeout: 100000, // request timeout
} 
  
  @NgModule({
    imports: [
    CommonModule,
    StoreModule.forRoot([]),
    EffectsModule.forRoot([]),
        EntityDataModule.forRoot(entityConfig), 
    ],
    providers: [
      AppDataServices,
      { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
      { provide: PersistenceResultHandler, useClass: AdditionalPropertyPersistenceResultHandler },
      { provide: EntityCollectionReducerMethodsFactory, useClass: AdditionalEntityCollectionReducerMethodsFactory },
      { provide: EntityServices, useExisting: AppDataServices },      
      EnterpriseDataService,
      AuthDataService,
      UserDataService,
      TeamDataService,
      SiteDataService,
      GoalDataService,
      MilestoneDataService,
      CategoryDataService,
      ActionDataService,
      DocumentDataService,
      CalculatorDataService,
      CommonDataService,
      SupplierDataService,
      ReductionDataService,

      EnterpriseCollectionService,
      AuthCollectionService,      
      UserCollectionService,      
      TeamCollectionService,      
      SiteCollectionService,      
      GoalCollectionService,      
      MilestoneCollectionService,
      CategoryCollectionService,      
      ActionCollectionService,      
      DocumentCollectionService,      
      CalculatorCollectionService,      
      CommonCollectionService,
      SupplierCollectionService,
      ReductionCollectionService
    ]
  })
  export class EntityStoreModule {
    constructor(
      entityDataService: EntityDataService,
      enterpriseDataService: EnterpriseDataService,
      siteDataService: SiteDataService,
      calculatorDataService: CalculatorDataService,
      userDataService: UserDataService,
      teamDataService: TeamDataService,
      authDataService: AuthDataService,
      goalDataService: GoalDataService,
      mileStoneDataService: MilestoneDataService,
      categoryDataService: CategoryDataService,
      actionDataService: ActionDataService,
      commonDataService: CommonDataService,
      documentDataService: DocumentDataService,
      supplierDataService: SupplierDataService,
      reductionDataService: ReductionDataService,
    ) {
      // Register Custom Data Services
      entityDataService.registerService('Enterprise', enterpriseDataService);
      entityDataService.registerService('Site', siteDataService);
      entityDataService.registerService('Calculator', calculatorDataService);
      entityDataService.registerService('User', userDataService);
      entityDataService.registerService('Auth', authDataService);
      entityDataService.registerService('Team', teamDataService);
      entityDataService.registerService('Goal', goalDataService);
      entityDataService.registerService('Milestone', mileStoneDataService);
      entityDataService.registerService('Category', categoryDataService);
      entityDataService.registerService('Action', actionDataService);
      entityDataService.registerService('Document', documentDataService);
      entityDataService.registerService('Common', commonDataService);
      entityDataService.registerService('Supplier', supplierDataService);
      entityDataService.registerService('Reduction', reductionDataService);
    }
  }