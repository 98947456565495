import { SplitPipe } from './split.pipe';
import { SearchPipe } from './searchPipe';
import { SortPipe } from './sortPipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    SearchPipe,
    SortPipe,
    SplitPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    SearchPipe,
    SortPipe,
    SplitPipe
  ]
})
export class PipesModule { }
