import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '@common/global/app.global.service';
import {
    DefaultDataService,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
    HttpUrlGenerator
} from '@ngrx/data';
import type { ReductionResponse, ReductionsResponse } from '@ecoshaper/lib';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpOptions } from '@ngrx/data/src/dataservices/interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class ReductionDataService extends DefaultDataService<ReductionResponse> {
    private readonly _reductionTranscript$ = new ReplaySubject<string>(1);
    public readonly reductionTranscript$ = this._reductionTranscript$.asObservable();

    constructor(
        readonly http: HttpClient,
        readonly httpUrlGenerator: HttpUrlGenerator,
        readonly globalService: GlobalService
    ) {
        super('Reduction', http, httpUrlGenerator, {root: `${globalService.baseURL}/gs`});
    }

    getAll(options?: HttpOptions) {
        return (super.getAll(options) as unknown as Observable<ReductionsResponse>)
            .pipe(map(res => {
                this._reductionTranscript$.next(res.transcript);
                return res.reductions;
            }));
    }

    getById(): Observable<ReductionResponse> {
        throw new Error('This collection doesn\'t support querying by id.');
    }
}

@Injectable()
export class ReductionCollectionService extends EntityCollectionServiceBase<ReductionResponse> {
    public readonly reductionTranscript$ = this.reductionService.reductionTranscript$;

    constructor(
        elementsFactory: EntityCollectionServiceElementsFactory,
        private readonly reductionService: ReductionDataService
    ) {
        super('Reduction', elementsFactory);
    }
}
