import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher, EntityActionOptions } from '@ngrx/data';
import { Observable, of} from 'rxjs';
import { map, catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthUser } from '@common/models/user';
import { GlobalService } from '@common/global/app.global.service';


@Injectable()
export class AuthDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global: GlobalService) {
    super('Auth', http, httpUrlGenerator);
  }

  login(user): Observable<AuthUser> {
    const headers = new HttpHeaders().set('X-hideError', 'true')
    return this.http.post<AuthUser>(this.global.loginUrl, user, {headers});
  }

  getUserbyAuth(): Observable<AuthUser> {
    const headers = new HttpHeaders().set('X-hideError', 'true');    
    return this.http.post<AuthUser>(this.global.authLoginUrl, {}, {headers})
      .pipe(
        catchError(({ error }) => of(null))
      )
  }

  serverRunning(): Observable<any> {
    return this.http.get<any>(this.global.baseURL+'/gs')
      .pipe(
        map(item=>(item.status == 'Running')),
        catchError(() =>of(false))
      );
  }

  changePassword(body: any): Observable<any> {
    return this.http.post(this.global.changePassword, body);
  }

  refreshToken(): Observable<any> {
    return this.http.get(this.global.refreshTokenUrl);
  }

  forgotPassword(body: any): Observable<any> {
    return this.http.post(this.global.forgotPassword + body, null);

  }

  createUserLog(logData:any): Observable<any>{
    return this.http.post<any>(this.global.userLogsUrl,logData);
  }

  getWithQuery(params: string | QueryParams | any): Observable<AuthUser[] | any[] | any> {
    const { data } = params;
    return of(data || []);
  }
}

@Injectable()
export class AuthCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: AuthDataService, private router: Router, private entityCacheDispatcher: EntityCacheDispatcher, private global: GlobalService) {
    super('Auth', elementsFactory);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  load(options?: EntityActionOptions): Observable<any> {
    return this.getUserbyAuth();
  }

  get currentUser$(): Observable<AuthUser> {
    return this.collection$.pipe(map((item: any) => item.currentUser));
  }

  getUserbyAuth(): Observable<AuthUser> {
    let currentUser: any = localStorage.getItem('currentUser');
    currentUser = currentUser ? JSON.parse(currentUser) || null : null;
    return (!currentUser ? of(null) : this.dataService.getUserbyAuth())
    .pipe(
      catchError(err=> of(null)),
      map(currentUser=> {
        let {user, authToken} = currentUser || {};
        if(currentUser)
          currentUser = {...currentUser, user: (user || {...currentUser, ...(authToken||{})}), token: (authToken||currentUser).token||''};
        currentUser = currentUser ? new AuthUser(currentUser):null ;
        this.setCurrentUser(currentUser);
        return currentUser;
      }));
  }

  login(userObj): Observable<AuthUser> {
    return this.dataService.login(userObj)
    .pipe(
      map(currentUser=> {
        currentUser = currentUser && currentUser.user ? new AuthUser(currentUser):null ;
        this.setCurrentUser(currentUser);
        return currentUser;
      }));
  }

  setCurrentUser(currentUser:AuthUser) {
    if(currentUser && currentUser.user && currentUser.user.roles && currentUser.user.roles.length>0) {
      let savedUser:any = localStorage.getItem("currentUser");
      savedUser = JSON.parse(savedUser) || {};
      currentUser = {
        ...savedUser,
        ...currentUser,
      };
      savedUser = {
        ...savedUser,
        token: currentUser.token,
        expiry: currentUser.expiry
      }
      localStorage.setItem("currentUser", JSON.stringify (savedUser));
      this.global.currentUserRoleId = +(currentUser?.user?.preferences?.role || currentUser.user.roles[0]).roleNumber;
      this.global.currentEnterpriseId = currentUser.user.enterpriseId;
      this.global.currentUserId = currentUser.user.id;
      this.global.currentUserToken = currentUser.token;
      this.global.currentUser = currentUser;
      this.global.currentUserName = currentUser.user.name;
      this.global.isSupplier = currentUser?.user?.preferences?.isSupplierSelected || false;
      this.global.isSupplyChain = currentUser?.user?.preferences?.isSupplyChainSelected || false;      
      this.global.dateFormat = currentUser.user.preferences ? currentUser.user.preferences.dateFormat || this.global.dateFormat: this.global.dateFormat;
      this.global.initFeatures();
    } else {
      localStorage.removeItem('access_token');
      localStorage.removeItem("currentUser");
      currentUser = null;
    }
    this.setData({ currentUser});
    this.setLoaded(true);
  }

  serverRunning(): Observable<any> {
    return this.dataService.serverRunning();
  }

  changePassword(body: any): Observable<any> {
    return this.dataService.changePassword(body);
  }

  refreshToken(): Observable<any> {
    return this.dataService.refreshToken();
  }

  forgotPassword(body: any): Observable<any> {
    return this.dataService.forgotPassword(body);
  }

  createUserLog(logData:any): Observable<any>{
    return this.dataService.createUserLog(logData);
  }

  logout() {
    let tutorial = localStorage.getItem('hideWalkthrough');
    let landing = localStorage.getItem('hideLanding');    
    let errorMsg = localStorage.getItem('errorMsg');    
    let cookie_consent = localStorage.getItem('cookie_consent_calculator');
    
    this.entityCacheDispatcher.setEntityCache({}); // Clear Store upon signOut
    localStorage.clear();
    
    if(cookie_consent)
    localStorage.setItem('cookie_consent_calculator', cookie_consent)
    if(tutorial)
    localStorage.setItem('hideWalkthrough', tutorial)
    if(landing)
    localStorage.setItem('hideLanding', landing)
    if(errorMsg)
    localStorage.setItem('errorMsg', errorMsg);

    this.router.navigateByUrl('/login', {replaceUrl: true});
    location.reload();
  }

}
