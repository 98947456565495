import { CreateTeamRequest } from '@ecoshaper/lib';
import { User } from './user';
import * as _ from 'lodash';
export class Team {
    id: number;
    color:  string = '#eee';
    createdDate: Date;
    description:  string;
    email:  string = '';
    enterpriseId:  string;
    modifiedDate: Date;
    name:  string;
    teamManager: User;
    teamManagerId?: number;
    teamMembers: User[];
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
    }
    static toRequest(team): CreateTeamRequest {
        let req:CreateTeamRequest = {} as CreateTeamRequest;
        let fields = ['teamMembers','name','teamManagerId','email','enterpriseId','description','color'];
     for (const key in team) {
            if (team[key]!=null && fields.includes(key)) {
                req[key]=team[key];                
            }
        }
        return req;
    }
}