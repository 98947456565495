import * as _ from 'lodash';
import { Enterprise as EnterpriseRequest } from '@ecoshaper/lib';
export class Enterprise {
    id?: number;
    addressLine1: string='';
    addressLine2: string='';
    categoryId: number=0;
    contactEmail: string='';
    contactName: string='';
    contactPhone: string='';
    contactTitle: string='';
    email: string='';
    isActive: boolean = true;
    isDeleted: boolean = false;
    logo: string='';
    name: string='';
    pincode: string='';
    website: string='';
    address: string='';
    maxUsers:number;
    maxEnterpriseUsers:number;
    preferences: any = { features: { goals: true, actions: true, calendar: true, dashboard: true, calculator: true, eco_print: true, white_label: false } };

    constructor(data) {
        for (const key in data) {
            if(data[key]){
                if (key.toLowerCase().includes('date')){
                    this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]);
                }                
                else if (key == 'preferences') {
                    if(_.isString(data[key]) && data[key] == '[object Object]')
                        this[key] = this.preferences;
                    else if (_.isString(data[key]))
                        this[key] = JSON.parse(data[key]) || this.preferences;
                    else
                        this[key] = data[key] || this.preferences;
                }
                else if(['id', 'categoryId'].includes(key)){
                    this[key] = +data[key] || 0;
                }                
                else
                    this[key] = data[key];
            }            
        }
    }

    toRequest(): EnterpriseRequest {
        let data:any = {...this, preferences: JSON.stringify(this.preferences)};
        let req: EnterpriseRequest = {} as EnterpriseRequest;
        let fields = ['name', 'email', 'website', 'address', 'addressLine1', 'addressLine2',
            'logo', 'contactName', 'contactTitle', 'contactPhone', 'contactEmail', 'pincode',
            'preferences', 'categoryId', 'isActive'];
        for (const key in data) {
            if (data[key]!=null && fields.includes(key)) {
                req[key]= data[key];
            }
        }
        return req;
    }

    /*toRequest(): EnterpriseRequest {
        let { name,
            email,
            website,
            address,
            addressLine1,
            addressLine2,
            logo,
            contactName,
            contactTitle,
            contactPhone,
            contactEmail,
            pincode,
            preferences,
            categoryId,
            isActive } = this;
        
        preferences = JSON.stringify(preferences);
        return new EnterpriseRequest(
            name,
            email,
            website,
            address,
            addressLine1,
            addressLine2,
            logo,
            contactName,
            contactTitle,
            contactPhone,
            contactEmail,
            pincode,
            preferences,
            categoryId,
            isActive)

    }*/
}

export interface EnterpriseCategory {
    id: number;
    name: string;
    description: string;
}