import {Milestone} from '@common/models/milestone';
import {GlobalService} from '@common/global/app.global.service';
import {AuthCollectionService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Update} from '@ngrx/entity';
import {Injectable} from '@angular/core';
import {
  DefaultDataService,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  HttpUrlGenerator,
  QueryParams
} from '@ngrx/data';

import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';


@Injectable()
export class MilestoneDataService extends DefaultDataService<any> {
  constructor(
      http: HttpClient,
      httpUrlGenerator: HttpUrlGenerator,
      private global: GlobalService,
      private authService: AuthCollectionService
  ) {
    super('Milestone', http, httpUrlGenerator);
  }

  getAll(): Observable<Milestone[]> {
    return this.getAllMilestones();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Milestone[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(milestone: Milestone): Observable<Milestone> {
    return this.http.post<any>(this.global.createMilestonesUrl, Milestone.toRequest(milestone))
    .pipe(
      map(item=> (new Milestone({...milestone, ...item }))),
      catchError((error) => throwError(error))
    );
  }

  update(milestone: Update<Milestone>): Observable<any> {
    let url = this.global.updateMilestonesUrl.replace('{milestoneNumber}', <string> milestone.id);
    return this.http.put<any>(url, Milestone.toRequest(milestone.changes, 'completionDate', 'status'))
    .pipe(
      map(item=> (new Milestone({...milestone.changes, ...item}))),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = this.global.deleteMilestonesUrl;
        url = url.replace('{enterpriseId}', user.enterpriseId.toString());
        url = url.replace('{milestoneNumber}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getAllMilestones(): Observable<Milestone[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
       let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : this.getMilestones(user.enterpriseId)
      }),
      map(result =>(result || [] ) .map(item=> new Milestone(item))),
      catchError(() => of([]))
    );
  }
  
  getMilestones(enterpriseId:any): Observable<any> {
    let url = this.global.getMilestonesUrl.replace('{enterpriseId}', enterpriseId.toString());
    return this.http.get<any>(url);
  }
}

@Injectable()
export class MilestoneCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: MilestoneDataService) {
    super('Milestone', elementsFactory);
  }

  get milestones$(): Observable<Milestone[]> {
    return this.entities$;
  }

  delete(entity: any): Observable<string | number> {
    let key = `${entity.goalId}/${entity.id}`;
    return this.dataService.delete(key);
  }
}
